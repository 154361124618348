import { Button, ButtonProps, Input } from "@chakra-ui/react";
import React, { ChangeEvent } from "react";
import { BsUpload } from "react-icons/bs";
// Style the Button component

type Props = {
  onFileChange: (e?: ChangeEvent<HTMLInputElement>) => void;
  buttonText?: string;
} & ButtonProps;

const FileInput = ({
  onFileChange,
  buttonText = "Upload a file",
  ...rest
}: Props) => {
  // Create a reference to the hidden file input element
  const hiddenFileInput = React.useRef<HTMLInputElement>(null);

  //This is required so if you remove a photo and try to re-upload
  //the same photo, it doesnt glitch out and not select it
  const handleClick = (
    event: React.MouseEvent<HTMLInputElement, MouseEvent>
  ) => {
    const element = event.target as HTMLInputElement;
    element.value = "";
  };

  return (
    <>
      {/*//TODO: Use theme here and create a brand*/}
      <Button
        leftIcon={<BsUpload />}
        // color="white"

        // bgColor="red.500"
        colorScheme="brand"
        // _hover={{ backgroundColor: "red" }}
        {...rest}
        onClick={() => hiddenFileInput.current?.click()}
      >
        {buttonText}
      </Button>
      <Input
        type="file"
        ref={hiddenFileInput}
        onChange={onFileChange}
        onSelect={(e) => {
          e.preventDefault();
        }}
        onClick={handleClick}
        multiple
        style={{ display: "none" }}
        accept=".png, .jpg, .jpeg"
      />
    </>
  );
};

export default FileInput;
