import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import apiClient from "../../apiClient/apiClient";
import { firebaseLogEvent } from "../../config/firebase";
import queryKeys from "../queryKeys";
import useAuthentication from "../useAuthentication";

type Props = {
  onSuccess?: () => void;
  onError?: () => void;
};

const useCloneRecipe = ({ onSuccess, onError }: Props) => {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuthentication();

  const { isLoading, mutate: cloneRecipeMutate } = useMutation(
    async (recipeId: string) => {
      await getAccessTokenSilently();

      await firebaseLogEvent("clone_recipe", { recipe_id: recipeId });
      return apiClient.cloneRecipe(recipeId, undefined);
    },
    {
      onSuccess: async (res) => {
        await queryClient.invalidateQueries({ queryKey: [queryKeys.recipes] });
        await queryClient.invalidateQueries({
          queryKey: [queryKeys.checkUserHasRecipeAdded],
        });
        toast.success("Recipe has been saved");
        onSuccess && onSuccess();
      },
      onError: (err) => {
        //TODO: Display validation errors from the BE if they exist.
        toast.error(`We ran into an error! Please try again - ${err}`);
      },
    }
  );

  return { isLoading, cloneRecipeMutate };
};

export default useCloneRecipe;
