/* eslint-disable react/no-unescaped-entities */
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Link,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useRouter } from "next/router";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useRegisterWithEmailAndPassword } from "../../hooks";
import { TextInput } from "../Inputs";
import PasswordInput from "../Inputs/PasswordInput";
import signUpUserSchema from "./SignUpUserSchema";
import SocialAuthenticationButtons from "./SocialAuthenticationButtons";

type Props = {
  onSignUpClick: () => void;
};

type SignUpUserVm = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  // Country?: string;
};

const SignInUser = ({ onSignUpClick }: Props) => {
  const methods = useForm<SignUpUserVm>({
    defaultValues: {},
    resolver: yupResolver(signUpUserSchema),
  });

  const router = useRouter();

  const { registerWithEmailAndPasswordMutate, errorMessage, isLoading } =
    useRegisterWithEmailAndPassword({});

  const authLoading = isLoading;

  const {
    register,
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = methods;

  const onSubmit: SubmitHandler<SignUpUserVm> = ({
    email,
    firstName,
    lastName,
    password,
  }) => {
    registerWithEmailAndPasswordMutate({
      email,
      firstName,
      lastName,
      password,
    });
    // if (!data.name) return;

    // const createRecipeCollectionCommand: CreateRecipeCollectionCommand = {
    //   name: data.name,
    //   description: data.description,
    // };
  };

  return (
    <Flex
      minH={"100vh"}
      align={"center"}
      justify={"center"}
      bg={useColorModeValue("gray.50", "gray.800")}
    >
      <Stack width="100%" spacing={8} mx={"auto"} maxW={"xl"} py={12} px={6}>
        <Stack align={"center"}>
          <Image
            src="/MainLogo.svg"
            alt="Cooking Logo"
            width={10}
            height={10}
          />
          <Heading fontSize={"4xl"}>Create an account</Heading>

          <Text fontSize={"md"} color={"gray.600"}>
            Already have an account?{" "}
            <Link onClick={onSignUpClick} color={"blue.400"}>
              Sign In
            </Link>
          </Text>
        </Stack>
        <Box
          rounded={"lg"}
          bg={useColorModeValue("white", "gray.700")}
          boxShadow={"lg"}
          p={8}
        >
          <Stack spacing={4}>
            <FormProvider {...methods}>
              <form
                // style={{ height: "100%" }}
                id="SignUpUserForm"
                onSubmit={handleSubmit(onSubmit)}
              >
                {errorMessage && (
                  <Alert status="error">
                    <AlertIcon />
                    {errorMessage}
                  </Alert>
                )}
                <TextInput
                  {...register("firstName")}
                  isRequired={true}
                  label="First Name"
                  errorMessage={errors.firstName?.message}
                />
                <TextInput
                  {...register("lastName")}
                  isRequired={true}
                  label="Last Name"
                  errorMessage={errors.lastName?.message}
                />
                <TextInput
                  {...register("email")}
                  isRequired={true}
                  label="Email"
                  errorMessage={errors.email?.message}
                />
                <PasswordInput
                  {...register("password")}
                  isRequired={true}
                  label="Password"
                  errorMessage={errors.password?.message}
                />
                <Stack spacing={5} mt={5}>
                  <Button
                    type="submit"
                    colorScheme={"brand"}
                    disabled={authLoading}
                    isLoading={authLoading}
                  >
                    Sign Up
                  </Button>

                  <SocialAuthenticationButtons authLoading={authLoading} />
                </Stack>
              </form>
            </FormProvider>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
};

export default SignInUser;
