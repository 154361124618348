import { Button, Stack, Text } from "@chakra-ui/react";
import { AiFillApple } from "react-icons/ai";
import { FcGoogle } from "react-icons/fc";
import { useSignInWithApple, useSignInWithGoogle } from "../../hooks";

type Props = {
  authLoading: boolean;
};

const SocialAuthenticationButtons = ({ authLoading }: Props) => {
  const { isLoading, signInWithGoogleMutate } = useSignInWithGoogle({});
  const { isLoading: isAppleSignInLoading, signInWithAppleMutate } =
    useSignInWithApple({});
  const isSocialAuthLoading = isLoading || isAppleSignInLoading;

  return (
    <>
      <Stack align={"center"}>
        <Text fontSize={"md"} color={"gray.600"}>
          Or continue with
        </Text>
      </Stack>
      <Stack
        align={"center"}
        justifyContent={"space-between"}
        wrap={"wrap"}
        gap="5px"
      >
        <div>
          <Button
            onClick={() => signInWithGoogleMutate()}
            w={200}
            leftIcon={<FcGoogle size={24} />}
            // colorScheme="white"
            colorScheme={"brand"}
            textColor="black"
            variant="outline"
            borderColor={"grey"}
            disabled={authLoading || isSocialAuthLoading}
            isLoading={authLoading || isSocialAuthLoading}
          >
            Sign in with Google
          </Button>
        </div>
        <div>
          <Button
            disabled={authLoading || isSocialAuthLoading}
            isLoading={authLoading || isSocialAuthLoading}
            colorScheme={"brand"}
            onClick={() => signInWithAppleMutate()}
            textColor="black"
            variant="outline"
            w={200}
            leftIcon={<AiFillApple size={24} color="black" />}
            // colorScheme="white"
            borderColor={"grey"}
          >
            Sign in with Apple
          </Button>
        </div>
      </Stack>
    </>
  );
};

export default SocialAuthenticationButtons;
