import { Button, Flex, Image, Text } from "@chakra-ui/react";
import "keen-slider/keen-slider.min.css";
import { useKeenSlider } from "keen-slider/react";
import { useEffect, useState } from "react";
import { FaInfoCircle } from "react-icons/fa";
import { PhotoProvider, PhotoView } from "react-photo-view";
import { Shimmer } from "react-shimmer";
import { RecipeImageVm } from "../../apiClient/clients";
import getImageFromRecipe from "../../utils/getImageFromRecipe";

type Props = {
  recipeImages?: RecipeImageVm[];
};

const ImageCarousel = ({ recipeImages }: Props) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [options, setOptions] = useState({
    initial: 0,
    slideChanged(slider: any) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
  });
  const [loaded, setLoaded] = useState(false);
  const [sliderRef, instanceRef] = useKeenSlider(options);

  //   useEffect(() => {
  //     setOptions({
  //       initial: 0,
  //       slideChanged(slider: any) {
  //         setCurrentSlide(slider.track.details.rel);
  //       },
  //       created() {
  //         setLoaded(true);
  //       },
  //     });
  //   }, [recipeImages]);

  useEffect(() => {
    instanceRef?.current?.update();
  }, [recipeImages]);

  if (!recipeImages?.length) {
    return <></>;
  }

  return (
    <>
      <div className="navigation-wrapper">
        <Flex ref={sliderRef} className="keen-slider">
          <PhotoProvider>
            {recipeImages?.map((image) => {
              return (
                <div
                  key={image.uniqueImageFileName}
                  className="keen-slider__slide"
                  style={{ overflow: "hidden" }}
                >
                  <PhotoView
                    src={getImageFromRecipe({
                      sizeOption: "800_800",
                      recipeImage: image,
                      height: 800,
                      width: 800,
                    })}
                  >
                    <Image
                      h="250px"
                      w="100%"
                      marginLeft={"auto"}
                      marginRight={"auto"}
                      src={getImageFromRecipe({
                        sizeOption: "800_800",
                        recipeImage: image,
                        height: 800,
                        width: 800,
                      })}
                      alt="Recipe Image"
                      objectFit="contain"
                      borderRadius={"lg"}
                      justifyContent="center"
                      fallback={
                        <Flex justifyContent={"center"}>
                          <Shimmer height={200} width={250} />{" "}
                        </Flex>
                      }
                    />
                  </PhotoView>
                </div>
              );
            })}
          </PhotoProvider>
        </Flex>
        {loaded && instanceRef.current && recipeImages?.length > 1 && (
          <>
            <Arrow
              left
              onClick={(e: any) =>
                e.stopPropagation() || instanceRef.current?.prev()
              }
              disabled={currentSlide === 0}
            />

            <Arrow
              onClick={(e: any) =>
                e.stopPropagation() || instanceRef.current?.next()
              }
              disabled={
                currentSlide ===
                instanceRef.current.track.details.slides.length - 1
              }
            />
          </>
        )}
      </div>
      {loaded && instanceRef.current && (
        <>
          <Flex justifyContent={"center"} gap="10px">
            <FaInfoCircle color="#8952e0" size="20px" />
            <Text fontStyle={"italic"}>Tap an image to view full screen</Text>
          </Flex>
          <div className="dots">
            {[
              ...Array(instanceRef.current.track.details.slides.length).keys(),
            ].map((idx) => {
              return (
                <Button
                  name="carousel-button-dot"
                  key={idx}
                  onClick={(e) => {
                    e.stopPropagation();
                    instanceRef.current?.moveToIdx(idx);
                  }}
                  className={"dot" + (currentSlide === idx ? " active" : "")}
                />
              );
            })}
          </div>
        </>
      )}
    </>
  );
};

const Arrow = (props: any) => {
  const disabled = props.disabled ? " arrow--disabled" : "";
  return (
    <svg
      onClick={props.onClick}
      className={`arrow ${
        props.left ? "arrow--left" : "arrow--right"
      } ${disabled}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      color="black"
    >
      {props.left && (
        <path
          d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z"
          fill="black"
        />
      )}
      {!props.left && (
        <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" fill="black" />
      )}
    </svg>
  );
};

export default ImageCarousel;
