import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import apiClient from "../../apiClient/apiClient";
import { firebaseLogEvent } from "../../config/firebase";
import queryKeys from "../queryKeys";
import useAuthentication from "../useAuthentication";

type Props = {
  onSuccess?: () => void;
  onError?: () => void;
};

const useDeleteShoppingList = ({ onSuccess, onError }: Props) => {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuthentication();

  const { isLoading, mutate: deleteShoppingListMutate } = useMutation(
    async (shoppingListId: string) => {
      await getAccessTokenSilently();
      await firebaseLogEvent("delete_shopping_list", {
        shopping_list_id: shoppingListId,
      });
      return apiClient.deleteShoppingList(shoppingListId, undefined);
    },
    {
      onSuccess: async (res) => {
        await queryClient.invalidateQueries({
          queryKey: [queryKeys.shoppingLists],
        });
        toast.success("Shopping list has been deleted");
        onSuccess && onSuccess();
      },
      onError: (err) => {
        //TODO: Display validation errors from the BE if they exist.
        toast.error(`We ran into an error! Please try again - ${err}`);
      },
    }
  );

  return { isLoading, deleteShoppingListMutate };
};

export default useDeleteShoppingList;
