const iosFriendlyMessages: any = {
  "argument-error":
    "Invalid arguments provided. Please check your input and try again.",
  "email-already-in-use":
    "The email address you provided is already in use. Please use a different email address.",
  "expired-action-code":
    "The action code you entered has expired. Please request a new one.",
  "invalid-verification-code":
    "The verification code you entered is invalid. Please check the code and try again.",
  "invalid-custom-token":
    "The custom token you provided is invalid. Please check the token and try again.",
  "invalid-email":
    "The email address you provided is not valid. Please check and try again.",
  "invalid-credential":
    "The credential you provided is invalid. Please check and try again.",
  "wrong-password":
    "The password you provided is incorrect. Please check and try again.",
  "invalid-phone-number":
    "The phone number you provided is not valid. Please check and try again.",
  "invalid-verification-id":
    "The verification ID you provided is not valid. Please check and try again.",
  "missing-verification-code":
    "The verification code is missing. Please provide a verification code.",
  "network-request-failed":
    "Network request failed. Please check your network connection and try again.",
  "operation-not-allowed":
    "This operation is not allowed. Please contact support if you believe this is an error.",
  "user-not-found":
    "We are unable to find an account with that information. We have recently migrated to a new authentication system. Therefore, if you have previously signed up you will need to sign back in again. We're sorry for the inconvenience.",
  "user-disabled":
    "This user account has been disabled. Please contact support for more information.",
  "user-mismatch":
    "The provided credentials do not match the current user. Please check and try again.",
  "weak-password":
    "The password you provided is too weak. Please provide a stronger password.",
  "too-many-requests":
    "Too many attempts have been made. Please try again later.",
  "user-token-expired": "Your session has expired. Please log in again.",
};

export default iosFriendlyMessages;
