import { Flex, FormControl, FormErrorMessage, Image } from "@chakra-ui/react";
import { ChangeEvent, useEffect, useRef } from "react";
import { RiDeleteBin5Line } from "react-icons/ri";
import { PhotoProvider, PhotoView } from "react-photo-view";
import { FileWithErrorMessage } from "../../types";
import { FileInput } from "../Inputs";
import EmptyImagePlaceholder from "./EmptyImagePlaceholder";

type Props = {
  files?: FileWithErrorMessage[];
  onFileChange: (e?: ChangeEvent<HTMLInputElement>) => void;
  errorMessage?: string;
  displayUploadPhotoButton?: boolean;
  onFileRemove?: (file: FileWithErrorMessage) => void;
};

type ImageCardProps = {
  file: FileWithErrorMessage;
};

const FileUploaderV3 = ({
  errorMessage,
  displayUploadPhotoButton,
  onFileChange,
  files,
  onFileRemove,
}: Props) => {
  const filesRef = useRef(files);

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    filesRef.current = files;
    return () =>
      files?.forEach(
        (file) => file.preview && URL.revokeObjectURL(file?.preview)
      );
  }, [files]);

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () =>
      filesRef.current?.forEach(
        (file) => file.preview && URL.revokeObjectURL(file?.preview)
      );
  }, []);

  //add use effect to cleanup the objectUrl to prevent memory leaks.

  const ImageWithEditControls = () => {
    <PhotoProvider>
      <PhotoView src={"/placeholder_image.png"}>
        <Image
          _hover={{ cursor: "pointer" }}
          borderRadius={"lg"}
          src={"/placeholder_image.png"}
          alt="Recipe Image"
          objectFit="contain"
          height={200}
        />
      </PhotoView>
    </PhotoProvider>;
  };

  const ImageCard = ({ file }: ImageCardProps) => (
    //TODO: Make the image sizes smaller on mobile. try to fit on one row if possible??
    <Flex
      // minW={"100px"}
      // maxW={["150px", "200px", "300px"]}
      h="150px"
      w="150px"
      direction={["column"]}
      gap="5px"
    >
      <Flex justifyContent={"end"}>
        <RiDeleteBin5Line
          size="24"
          color="red"
          // variant={"link"}
          onClick={() => onFileRemove && onFileRemove(file)}
        />
      </Flex>
      <Flex>
        <PhotoView src={file.preview}>
          <Image
            // maxW={["150px", "200px", "300px"]}
            h="150px"
            w="150px" //Can play with this to try get images to sit differently
            src={file.storageUrl ? file.storageUrl : file.preview}
            alt="Recipe Logo"
            objectFit={"cover"}
            borderRadius="lg"
          />
        </PhotoView>
      </Flex>
    </Flex>
  );

  const ImageCardDisplay = () => {
    return (
      <Flex
        gap="35px"
        wrap="wrap"
        justifyContent={"center"}
        direction={["column", "row"]}
      >
        <PhotoProvider>
          {files?.map((file, index) => (
            <ImageCard key={index} file={file} />
          ))}
        </PhotoProvider>
      </Flex>
    );
  };

  return (
    <>
      <Flex mb="20px" justify={"center"} objectFit="contain">
        <FormControl
          display={"flex"}
          isInvalid={!!errorMessage}
          flexDirection="column"
          justifyContent={"center"}
          alignItems="center"
        >
          {files?.length ? <ImageCardDisplay /> : <EmptyImagePlaceholder />}
          <FormErrorMessage>{errorMessage}</FormErrorMessage>
        </FormControl>
      </Flex>
      {displayUploadPhotoButton && (
        <Flex justify={"center"} mt="25px">
          <FileInput
            onFileChange={onFileChange}
            buttonText="Upload some photos!"
          />
        </Flex>
      )}
    </>
  );
};

export default FileUploaderV3;
