import { useMutation } from "@tanstack/react-query";
import { useRouter } from "next/router";
import {
  firebaseLogEvent,
  firebaseSendPasswordResetEmail,
} from "../../config/firebase";
import logger from "../../utils/logger";

type Props = {
  onSuccess?: () => void;
  onError?: () => void;
};

const useSendPasswordResetEmail = ({ onSuccess, onError }: Props) => {
  const router = useRouter();

  const { isLoading, mutate: sendPasswordResetEmailMutate } = useMutation(
    async (email: string) => {
      await firebaseSendPasswordResetEmail(email);
      await firebaseLogEvent("password_reset", { email });
    },
    {
      onSuccess: async (user) => {
        onSuccess && onSuccess();
      },
      onError: (err) => {
        logger.error(err, "Error sending password reset email");
        // toast.error(`We ran into an error! Please try again - ${err}`);
      },
    }
  );

  return { isLoading, sendPasswordResetEmailMutate };
};

export default useSendPasswordResetEmail;
