import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useRouter } from "next/router";
import { toast } from "react-hot-toast";
import apiClient from "../../apiClient/apiClient";
import { firebaseLogEvent, firebaseLogout } from "../../config/firebase";
import useAuthentication from "../useAuthentication";

type Props = {
  onSuccess?: () => void;
  onError?: () => void;
};

const useDeleteUser = ({ onSuccess, onError }: Props) => {
  const queryClient = useQueryClient();
  const router = useRouter();
  const { getAccessTokenSilently } = useAuthentication();

  const { isLoading, mutate: deleteUserMutate } = useMutation(
    async () => {
      await getAccessTokenSilently();
      await firebaseLogEvent("delete_user");
      return apiClient.deleteUser(undefined);
    },
    {
      onSuccess: async (res) => {
        await router.push("/");
        await firebaseLogout();
        onSuccess && onSuccess();
      },
      onError: (err) => {
        //TODO: Display validation errors from the BE if they exist.
        toast.error(`We ran into an error! Please try again - ${err}`);
      },
    }
  );

  return { isLoading, deleteUserMutate };
};

export default useDeleteUser;
