import { useMutation } from "@tanstack/react-query";
import { FirebaseError } from "firebase/app";
import { useRouter } from "next/router";
import { useState } from "react";
import { FirebaseAuthProvider } from "../../apiClient/clients";
import {
  createNewUserAPI,
  firebaseCreateUserWithEmailAndPassword,
} from "../../config/firebase";
import logger from "../../utils/logger";
import handleError from "./handleErrorMessage";

type Props = {
  onSuccess?: () => void;
  onError?: () => void;
};

const useRegisterWithEmailAndPassword = ({ onSuccess, onError }: Props) => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );

  const router = useRouter();

  const { isLoading, mutate: registerWithEmailAndPasswordMutate } = useMutation(
    async (credentials: {
      firstName: string;
      lastName: string;
      email: string;
      password: string;
    }) =>
      await firebaseCreateUserWithEmailAndPassword(
        credentials.firstName,
        credentials.lastName,
        credentials.email,
        credentials.password
      ),
    {
      onSuccess: async ({ user, firstName, lastName }) => {
        await createNewUserAPI(
          user,
          FirebaseAuthProvider.Email,
          firstName,
          lastName
        );
        await router.push("/recipes");

        onSuccess && onSuccess();
      },
      onError: (err: FirebaseError) => {
        logger.error(err, "Error registering with email and password");
        const errorMessage = handleError(err);
        setErrorMessage(errorMessage);
      },
    }
  );

  return { isLoading, registerWithEmailAndPasswordMutate, errorMessage };
};

export default useRegisterWithEmailAndPassword;
