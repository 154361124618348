import { Box } from "@chakra-ui/react";

type Props = {
  children?: React.ReactNode;
  border?: boolean;
  overFlow?: boolean;
};

const Paper = ({ children, border = true, overFlow = true }: Props) => {
  return (
    <Box
      bg={border ? "white" : ""}
      h="100%"
      ml={"auto"}
      mr={"auto"}
      maxW="container.xl"
      width={["90%", "95%", "95%", "95%", "100%"]}
      // p={"25px"}
      my={["5%", "2%"]}
      border={border ? "0.0625rem solid #dee2e6" : ""}
      overflowY={overFlow ? "auto" : "unset"}
      borderRadius={"lg"}
    >
      {children}
      {/* <Box
          
          // w={["80%", "90%"]}
          // my={["5%"]}
          maxW="container.xl"
          m={["2%"]}
          p="25px"
          h={["90%", "85%"]}
          bg={useColorModeValue("white", "gray.900")}
        >
          {children}
        </Box> */}
    </Box>
  );
};

export default Paper;
