import { Button, Flex } from "@chakra-ui/react";
import "react-photo-view/dist/react-photo-view.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import { useRouter } from "next/router";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useImportFromInstagram } from "../../../hooks";
import TextInput from "../../Inputs/TextInput";

type Props = {
  onBack?: () => void;
  onSave?: () => void;
};

type FormInputs = {
  url: string;
};

const ImportFromInstagramForm = ({ onBack, onSave }: Props) => {
  const router = useRouter();

  const { importFromInstagramMutate, isLoading } = useImportFromInstagram({});

  const methods = useForm<FormInputs>({
    defaultValues: {},
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmit: SubmitHandler<FormInputs> = (data) => {
    importFromInstagramMutate(data.url);
    toast.loading(
      "We're currently processing this recipe. it can take between 30-60 seconds to generate it. We'll send you a notification once its ready.",
      {
        duration: 5000,
      }
    );
    onSave && onSave();
  };

  const ActionButtons = () => {
    return (
      <Button
        mt={4}
        colorScheme="brand"
        isLoading={isLoading}
        type="submit"
        form="ImportFromInstagramForm"
      >
        Save
      </Button>
    );
  };

  //TODO: Text Input's throw controlled/uncontrolled errors whne using controller, but NumberINput's don't 👀
  return (
    <FormProvider {...methods}>
      <Flex h="100%" direction={"column"}>
        <form
          onKeyPress={(e) => {
            if (e.key === "enter") {
              e.preventDefault();
            }
          }}
          // style={{ height: "100%" }}
          id="ImportFromInstagramForm"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Flex p="25px" direction="column" gap="10px" height="100%">
            {/*//TODO: Fix all of the gaps / padding etc. Use a flex on the Form and
        just set a gap there.*/}
            <TextInput
              variant="flushed"
              // variant={textInputVariant}
              {...register("url")}
              isRequired={true}
              label="Instagram URL"
              placeholder={"Instagram reel or post link"}
            />
          </Flex>
        </form>

        <ActionButtons />
      </Flex>
    </FormProvider>
  );
};

export default ImportFromInstagramForm;
