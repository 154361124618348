/* eslint-disable react/no-unescaped-entities */
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Link,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import NextLink from "next/link";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useLogInWithEmailAndPassword } from "../../hooks";
import { TextInput } from "../Inputs";
import PasswordInput from "../Inputs/PasswordInput";
import signInUserSchema from "./SignInUserSchema";
import SocialAuthenticationButtons from "./SocialAuthenticationButtons";

type Props = {
  onSignUpClick: () => void;
};

type SignInUserVm = {
  email: string;
  password: string;
  // Country?: string;
};

const SignInUser = ({ onSignUpClick }: Props) => {
  const methods = useForm<SignInUserVm>({
    defaultValues: {},
    resolver: yupResolver(signInUserSchema),
  });

  const { isLoading, logInWithEmailAndPasswordMutate, errorMessage } =
    useLogInWithEmailAndPassword({});

  const authLoading = isLoading;

  const {
    register,
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = methods;

  const onSubmit: SubmitHandler<SignInUserVm> = (data) => {
    logInWithEmailAndPasswordMutate({
      email: data.email,
      password: data.password,
    });
  };

  return (
    <Flex
      minH={"100vh"}
      align={"center"}
      justify={"center"}
      bg={useColorModeValue("gray.50", "gray.800")}
    >
      <Stack width="100%" spacing={8} mx={"auto"} maxW={"xl"} py={12} px={6}>
        <Stack align={"center"}>
          <Image
            src="/MainLogo.svg"
            alt="Cooking Logo"
            width={10}
            height={10}
          />
          <Heading fontSize={"4xl"}>Sign in</Heading>

          <Text fontSize={"md"} color={"gray.600"}>
            Don't have an account?{" "}
            <Link onClick={onSignUpClick} color={"blue.400"}>
              Sign Up
            </Link>
          </Text>
        </Stack>
        <Box
          rounded={"lg"}
          bg={useColorModeValue("white", "gray.700")}
          boxShadow={"lg"}
          p={8}
        >
          <Stack spacing={4}>
            <FormProvider {...methods}>
              <form
                // style={{ height: "100%" }}
                id="SignInUserForm"
                onSubmit={handleSubmit(onSubmit)}
              >
                {errorMessage && (
                  <Alert status="error">
                    <AlertIcon />
                    {errorMessage}
                  </Alert>
                )}

                <TextInput
                  {...register("email")}
                  isRequired={true}
                  label="Email"
                  errorMessage={errors.email?.message}
                />
                <PasswordInput
                  {...register("password")}
                  isRequired={true}
                  label="Password"
                  errorMessage={errors.password?.message}
                />
                <Stack spacing={5}>
                  <Stack
                    direction={{ base: "column", sm: "row" }}
                    align={"start"}
                    justify={"space-between"}
                  >
                    {/* <Checkbox>Remember me</Checkbox> */}
                    <Link
                      as={NextLink}
                      href="/signin/forgot-password"
                      color={"blue.400"}
                    >
                      Forgot password?
                    </Link>
                  </Stack>
                  <Button
                    colorScheme={"brand"}
                    type="submit"
                    disabled={authLoading}
                    isLoading={authLoading}
                  >
                    Sign in
                  </Button>
                  <SocialAuthenticationButtons authLoading={authLoading} />
                </Stack>
              </form>
            </FormProvider>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
};

export default SignInUser;
