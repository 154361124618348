export enum Measurement {
  Tsp,
  Tbps,
  Ml,
  L,
  Cup,
  G,
  Kg,
}

export type FileWithErrorMessage = {
  file?: File;
  preview?: string;
  errorMessage?: string;
  imageId?: string;
  storageUrl?: string;
};
