import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import apiClient from "../../apiClient/apiClient";
import { UpdateRecipeCommand } from "../../apiClient/clients";
import uploadImage from "../../apiClient/uploadImage";
import { firebaseLogEvent } from "../../config/firebase";
import logger from "../../utils/logger";
import queryKeys from "../queryKeys";
import useAuthentication from "../useAuthentication";

//NOTE: This is a workaround for azure functions not supporting [FromForm] as well as NSwag issues.
//Essentially, when the request succeeds fire off a request to  upload the image.

export type UpdateRecipeRequest = {
  updateRecipeCommand: UpdateRecipeCommand;
  imageFormData?: FormData;
};

type Props = {
  onSuccess?: () => void;
  onError?: () => void;
};

const useUpdateRecipe = ({ onSuccess, onError }: Props) => {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuthentication();

  const { isLoading, mutate: updateRecipeMutate } = useMutation(
    async ({ updateRecipeCommand, imageFormData }: UpdateRecipeRequest) => {
      await getAccessTokenSilently();

      await firebaseLogEvent("update_recipe", { id: updateRecipeCommand.id });

      //NOTE: This is not ideal.
      const updateRecipe = await apiClient.updateRecipe(
        updateRecipeCommand.id,
        updateRecipeCommand
      );
      if (imageFormData) {
        const toastId = toast.loading("Uploading images... 📷");
        try {
          await uploadImage(
            imageFormData,
            updateRecipe.id,
            (progressEvent: any) => {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );

              toast.loading(`Uploading images... ${percentCompleted}%`, {
                id: toastId,
              });
            }
          );
        } catch (error) {
          console.error(
            `Failed to upload images for recipeId: ${updateRecipeCommand.id}`
          );

          logger.error(
            `Failed to upload images for recipeId: ${updateRecipeCommand.id}`
          );
          toast.error(
            "We ran into an error uploading images. The recipe has been updated."
          );
        } finally {
          toast.dismiss(toastId);
        }
      }
      return updateRecipe;
    },
    {
      onSuccess: async (res) => {
        //NOTE: not ideal
        // imageFormData && (await uploadImage(imageFormData, res.id));
        await queryClient.invalidateQueries({ queryKey: [queryKeys.recipes] });

        toast.success("Updated recipe! 👨‍🍳");
        onSuccess && onSuccess();
      },
      onError: (err) => {
        //TODO: Display validation errors from the BE if they exist.
        logger.error(err, "Error updating recipe");
        toast.error(`We ran into an error! Please try again - ${err}`);
      },
    }
  );

  return { isLoading, updateRecipeMutate };
};

export default useUpdateRecipe;
