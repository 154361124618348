import { Container, Flex, useColorModeValue } from "@chakra-ui/react";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { useAuthContext } from "../../contexts/AuthContext";
import useSafeAreaInsets from "../../hooks/useSafeAreaInsets";
import { LoadingSpinner } from "../LoadingSpinner";
import Navbar from "../Navbar/Navbar";
import Paper from "./Paper";

type Props = {
  pageTitle?: string;
  children?: React.ReactNode;
  border?: boolean;
  overFlow?: boolean;
};

const AuthenticatedLayout = ({
  pageTitle,
  children,
  border = true,
  overFlow = true,
}: Props) => {
  //TODO: Add this back in?
  // const { loading, isAuthenticated } = useAuth();
  const router = useRouter();
  const insets = useSafeAreaInsets();
  const { user, loading, isAuthenticated } = useAuthContext();

  useEffect(() => {
    if (!loading && !isAuthenticated) {
      router.push("/signin");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, isAuthenticated]);

  // useCapacitorAuth();

  const bgColor = useColorModeValue("gray.50", "gray.900");

  if (loading) {
    return <LoadingSpinner fullHeight />;
  }

  // If user is not authenticated, return null or a suitable placeholder
  if (!isAuthenticated) {
    return null;
  }

  return (
    <Container maxW={"100vw"} p={0} bg={bgColor}>
      {/*//TODO: Add a proper loading scenario here??? (Or leave it blank as below, so it doesnt show a weird loading spinner at the start) */}
      {/*//TODO: Add this back in? It stops SSR crawler / social media preview from working */}
      {
        /*loading || !router.isReady */ false ? (
          <>
            <LoadingSpinner fullHeight />
          </>
        ) : (
          //TODO: Make sure DVH is supported everywhere. It seems to be ok, but this automatically accounts for mobile devices e.g. tabs, the browser bar etc
          //TODO: More Mobile testing with pos=fixed. sometimes the scroll bar seems a little janky?
          // <Flex h={"100dvh"} w="100vw" direction="column">
          <Flex
            bg={bgColor}
            pos={"fixed"}
            // style={{
            //   height: "100vh",
            //   maxHeight: "100%",
            // }}
            h={`calc(100% - ${insets?.insets.top || 0}px)`}
            w="100vw"
            direction="column"
          >
            <Navbar />
            <Paper overFlow={overFlow} border={border}>
              {children}
            </Paper>
          </Flex>
        )
      }
    </Container>
  );
};

export default AuthenticatedLayout;
