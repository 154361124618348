import toast from "react-hot-toast";
import { isProblemDetailsErrorResponse, ProblemDetails } from ".";
import isApiLimitError from "./isApiLimitError";
import logger from "./logger";

const reactQueryOnApiLimitError = (
  err: any,
  onError?: (err: ProblemDetails) => void
) => {
  //We return a ProblemDetails error from the BE if the user limits are reached e.g. max 10 recipes in a collection.
  //So we need to check if the error is a ProblemDetails error and if so, display the error message to the user.
  try {
    if (onError && err?.response) {
      const parsedResponse = JSON.parse(err.response);
      if (
        isProblemDetailsErrorResponse(parsedResponse) &&
        isApiLimitError(parsedResponse)
      ) {
        return onError(parsedResponse);
      }
    }
  } catch (error) {
    console.error(error);
  }

  toast.error(`We ran into an error! Please try again - ${err?.message}`);
  //TODO: Display validation errors from the BE if they exist.
  logger.error(err, "We ran into an error! Please try again");
};

export default reactQueryOnApiLimitError;
