import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  forwardRef,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
} from "@chakra-ui/react";
import { useState } from "react";
import { preventEnterKeySubmission } from "../../utils";

//TODO: Extend from normal InputProps
type Props = {
  errorMessage?: string;
  label?: string;
  isRequired?: boolean;
  height?: string;
} & InputProps;

const PasswordInput = forwardRef<Props, "div">((props, ref) => {
  const { errorMessage, label, isRequired, ...rest } = props;
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);
  const hasError = !!errorMessage;

  return (
    <>
      <FormControl isRequired={isRequired} isInvalid={hasError}>
        {label && <FormLabel>{label}</FormLabel>}
        <InputGroup>
          <Input
            type={show ? "text" : "password"}
            focusBorderColor="brand.500"
            as={"input"}
            ref={ref}
            {...rest}
            //Could be a better way to do this, but: We want to show the required asterisk, but dont want the native validation to show.
            //If aria-required is set, then the native validation triggers the popup and doesn't show the yup/form validation error message.
            isRequired={false}
            onKeyPress={preventEnterKeySubmission}
            // {...register("name", {
            //   required: "This is required",
            //   minLength: { value: 4, message: "Minimum length should be 4" },
            // })}
          />
          <InputRightElement width="4.5rem">
            <Button h="1.75rem" size="sm" onClick={handleClick}>
              {show ? "Hide" : "Show"}
            </Button>
          </InputRightElement>
        </InputGroup>

        <FormErrorMessage>{errorMessage}</FormErrorMessage>
      </FormControl>
    </>
  );
});

export default PasswordInput;
