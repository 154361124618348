import { CheckCircleIcon } from "@chakra-ui/icons";
import {
  Button,
  Center,
  Flex,
  List,
  ListIcon,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { RiVipCrownLine } from "react-icons/ri";
import { useAuthentication } from "../../hooks";
import useGetUser from "../../hooks/users/useGetUser";
import { brand500Color } from "../../pages/_app";

type Props = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  onToggle?: () => void;
  onCancel?: () => void;
  apiLimitError?: string;
};

const UpgradeModal = ({
  isOpen,
  onClose,
  onOpen,
  onCancel,
  apiLimitError,
}: Props) => {
  const { isLoading: loading, isAuthenticated } = useAuthentication();

  const { isLoading: isGetUserLoading, data: user } =
    useGetUser(isAuthenticated);

  const titleSubheader = apiLimitError
    ? apiLimitError
    : "You've hit your limit. Please choose from one of our paid plans to continue.";

  const MaxLimitTier = () => (
    <Flex
      flexDirection={"column"}
      border={"1px solid #f2f3ff"}
      borderRadius="10px"
      boxShadow={"0 20px 30px -10px rgba(29, 1, 80, .1)"}
      padding="10px"
      background={"white"}
    >
      {/* <Text fontWeight={"bold"} fontSize="xl">
        Maximum Limit Reached.
      </Text> */}
      <Text fontWeight={"bolder"} fontSize="md">
        Hey there, enthusiast! 🌟 You have reached the limit for items in this
        category. Perhaps consider organizing them into multiple groups or
        refining your current list? Thanks for being a valued Premium member!
      </Text>
    </Flex>
  );

  const UpgradeToPremiumTier = () => (
    <Flex
      flexDirection={"column"}
      border={"1px solid #f2f3ff"}
      borderRadius="10px"
      boxShadow={"0 20px 30px -10px rgba(29, 1, 80, .1)"}
      padding="10px"
      background={"white"}
    >
      <Text fontWeight={"bold"} fontSize="xl">
        Upgrade to Premium and enjoy:
      </Text>
      <List spacing={2} mb={4}>
        <ListItem>
          <Flex alignItems={"center"}>
            <ListIcon as={CheckCircleIcon} color="brand.500" />
            <Text fontSize={"md"} fontWeight="medium">
              Unlimited recipes — never limit your culinary journey.
            </Text>
          </Flex>
        </ListItem>
        <ListItem>
          <Flex alignItems={"center"}>
            <ListIcon as={CheckCircleIcon} color="brand.500" />
            <Text fontSize={"md"} fontWeight="medium">
              Instant Instagram integration — effortlessly import recipes
              directly from your feed.
            </Text>
          </Flex>
        </ListItem>
        <ListItem>
          <Flex alignItems={"center"}>
            <ListIcon as={CheckCircleIcon} color="brand.500" />
            <Text fontSize={"md"} fontWeight="medium">
              Exclusive access to add multiple images per recipe — showcase
              every delicious angle.
            </Text>
          </Flex>
        </ListItem>
        <ListItem>
          <Flex alignItems={"center"}>
            <ListIcon as={CheckCircleIcon} color="brand.500" />
            <Text fontSize={"md"} fontWeight="medium">
              Enhanced recipe collections — add more dishes and share your
              curated list with friends.
            </Text>
          </Flex>
        </ListItem>
        {/* <ListItem>
          <Flex alignItems={"center"}>
            <ListIcon as={CheckCircleIcon} color="brand.500" />
            <Text>Ad-free experience — focus on what matters, the food!</Text>
          </Flex>
        </ListItem>
        <ListItem>
          <Flex alignItems={"center"}>
            <ListIcon as={CheckCircleIcon} color="brand.500" />
            <Text>Priority support — get answers when you need them.</Text>
          </Flex>
        </ListItem> */}
      </List>
    </Flex>
  );

  return (
    <>
      <Modal size={"xl"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay backdropFilter="auto" backdropBlur="25px">
          <ModalContent bgColor={"#f4f2f8"}>
            <ModalHeader fontSize="lg" fontWeight="medium">
              <Center>
                <Flex
                  gap="10px"
                  fontSize={"x-large"}
                  alignItems={"center"}
                  flexDirection={"column"}
                >
                  <RiVipCrownLine color={brand500Color} size="42" />
                  <Text>
                    {user?.isPaidUser
                      ? "Sorry! Max Limit Reached"
                      : "Upgrade to premium"}
                  </Text>
                  <Text fontWeight={"normal"} fontSize="md">
                    {titleSubheader}
                  </Text>
                </Flex>
              </Center>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {user?.isPaidUser ? <MaxLimitTier /> : <UpgradeToPremiumTier />}
            </ModalBody>
            <ModalFooter>
              <Button
                variant={"outline"}
                colorScheme="brand"
                mr={3}
                onClick={onClose}
              >
                Close
              </Button>
              {!user?.isPaidUser && (
                <Button colorScheme={"brand"}>Subscribe</Button>
              )}
            </ModalFooter>
          </ModalContent>
        </ModalOverlay>
      </Modal>
    </>
  );
};

export default UpgradeModal;
