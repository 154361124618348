import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  InputProps,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput as ChakraNumberInput,
  NumberInputField,
  NumberInputStepper,
} from "@chakra-ui/react";
import { useState } from "react";
import { ControllerRenderProps, useFormContext } from "react-hook-form";

//TODO: Get the generic types working here without requiring the any/any 'TFieldValues'
//TODO: Look into having the react  hook form controller in here and just passing in a control and rendering the field
//Potentially this as a solution: https://dev.to/texmeijin/component-design-idea-using-react-hook-form-v7-ie0
type CustomInputProps = {
  field: ControllerRenderProps<any, any>;
  label?: string;
  errorMessage?: string;
  placeholder?: string;
  allowDecimals?: boolean;
  isRequired?: boolean;
} & InputProps;

const NumberInput = ({
  label,
  errorMessage,
  placeholder,
  allowDecimals = true,
  isRequired = false,
  isReadOnly,
  field: { ref, value, ...restField },
  w,
  h,
  max,
}: CustomInputProps) => {
  const hasError = !!errorMessage;
  const [fieldValue, setFieldValue] = useState(value);
  const { getValues, setValue } = useFormContext(); // retrieve all hook methods

  //If value is empty, set it back to undefined. It converts it to an empty string instead which causes havoc on the BE.
  const handleChange = (input: string) => {
    if (!input) {
      // setFieldValue(input);
      return setValue(restField.name, undefined);
      return restField.onChange(undefined);
    }

    // setFieldValue(input);
    const val = allowDecimals ? input : input.replaceAll(".", "");
    setValue(restField.name, parseFloat(val));
    // restField.onChange(allowDecimals ? input : input.replaceAll(".", ""));
  };

  const handleBlur = (input: string) => {
    if (!input) {
      // setFieldValue(input);
      return setValue(restField.name, undefined);
      return restField.onChange(undefined);
    }

    // setFieldValue(input);
    const val = allowDecimals ? input : input.replaceAll(".", "");
    setValue(restField.name, parseFloat(val));
    // restField.onChange(allowDecimals ? input : input.replaceAll(".", ""));
  };

  // useEffect(() => {
  //   if (value !== fieldValue) {
  //     setFieldValue(value || "");
  //   }
  // }, [value]);

  const heightProp = { height: h };

  return (
    <>
      <FormControl
        isRequired={isRequired}
        isInvalid={hasError}
        w={w ? w : "100%"}
        // h={h ? h : "initial"}
      >
        {label && <FormLabel htmlFor={restField.name}>{label}</FormLabel>}
        <ChakraNumberInput
          {...(h && heightProp)}
          focusBorderColor="brand.500"
          onKeyPress={(e) => {
            //Number INput = Enter not enter like textfields etc 🤷
            if (e.key.toLowerCase() === "enter") {
              e.preventDefault();
            }
          }}
          variant={"flushed"}
          placeholder={placeholder}
          {...restField}
          value={getValues(restField.name) || ""}
          min={0}
          // onChange={handleChange}
          onBlur={(e) => handleBlur(e.target.value)}
          isReadOnly={isReadOnly}
          max={max && typeof max === "number" ? max : undefined}
        >
          <NumberInputField
            placeholder={placeholder}
            ref={ref}
            name={restField.name}
            {...(h && heightProp)}
          />
          {!isReadOnly && (
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          )}
        </ChakraNumberInput>
        {errorMessage && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
      </FormControl>
    </>
  );
};

export default NumberInput;
