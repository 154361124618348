import { useQuery } from "@tanstack/react-query";
import toast from "react-hot-toast";
import apiClient from "../../apiClient/apiClient";
import logger from "../../utils/logger";
import queryKeys from "../queryKeys";
import useAuthentication from "../useAuthentication";

const useCheckUserHasRecipeAdded = (
  loadingAuth: boolean,
  recipeId?: string,
  isUserAuthenticated = false
) => {
  const { getAccessTokenSilently, isAuthenticated } = useAuthentication();

  const { isLoading, data, isError, isSuccess, isFetching } = useQuery(
    [queryKeys.checkUserHasRecipeAdded],
    async () => {
      await getAccessTokenSilently();
      return apiClient.checkUserHasRecipeAdded(recipeId || "", undefined);
    },

    {
      onSuccess: (data) => {},

      onError: (error: any) => {
        logger.error({ error, isAuthenticated }, "Error retrieving user data");
        toast.error(
          `Status: ${error.status}- We ran into an error! Please try again - ${error} - Authentication status: ${isAuthenticated}`
        );
      },
      enabled: isUserAuthenticated && !!recipeId && !loadingAuth,
    }
  );

  return { isLoading, isError, data, isSuccess, isFetching };
};

export default useCheckUserHasRecipeAdded;
