import { Button, ButtonProps } from "@chakra-ui/react";
import { useRouter } from "next/router";

type Props = {
  onClick?: () => void;
  text: string;
  isLoading: boolean;
  returnTo?: string;
} & ButtonProps;

const SignInButton = ({
  onClick,
  text,
  isLoading,
  returnTo,
  ...rest
}: Props) => {
  // const { loginWithRedirect } = useAuthentication();
  const router = useRouter();

  // const nativeLogin = async () => {
  //   await loginWithRedirect({
  //     async openUrl(url) {
  //       // Redirect using Capacitor's Browser plugin
  //       await Browser.open({
  //         url,
  //         windowName: "_self",
  //       });
  //     },
  //   });
  // };

  const handleClick = async () => {
    router.push("/signin");
    // if (isNative) {
    //   await nativeLogin();
    // } else {
    //   loginWithRedirect({
    //     appState: {
    //       returnTo,
    //     },
    //   });
    // }
  };

  return (
    <Button
      onClick={() => handleClick()}
      type="button"
      isLoading={isLoading}
      colorScheme={"brand"}
      {...rest}
    >
      {text}
    </Button>
  );
};

export default SignInButton;
