import { Flex, Text } from "@chakra-ui/react";
import { IconType } from "react-icons";

type Props = {
  footer: string;
  Icon: IconType;
  innerContent: string;
};

const MiniInformationCard = ({ footer, Icon, innerContent }: Props) => (
  <Flex
    flexDir={"column"}
    padding={"10px"}
    minW="75px"
    maxW="125px"
    //set a maxH  of 100px if you want it to grow. If not, just set it as a static height so they all look the same.
    h="90px"
    bgColor={"brand.100"}
    borderRadius="10px"
    marginBottom={"10px"}
    gap="5px"
    border={"2px solid #8952e0"}
    // justifyContent={"space-between"}
  >
    <Flex alignItems={"center"} gap="10px">
      <Flex>
        <Icon color="#8952e0" size="20px" />
      </Flex>
      <Flex>
        <Text fontWeight={"bold"} color="brand.700">
          {innerContent}
        </Text>
      </Flex>
    </Flex>
    <Flex justifyContent={"center"}>
      <Text fontSize={"md"} align="center">
        {footer}
      </Text>
    </Flex>
  </Flex>
);

export default MiniInformationCard;
