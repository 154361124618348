import { Container, Flex, useColorModeValue } from "@chakra-ui/react";
import { useRouter } from "next/router";
import useSafeAreaInsets from "../../hooks/useSafeAreaInsets";
import { LoadingSpinner } from "../LoadingSpinner";
import Navbar from "../Navbar/Navbar";
import Paper from "./Paper";

type Props = {
  pageTitle?: string;
  children?: React.ReactNode;
  border?: boolean;
  overFlow?: boolean;
};

const UnAuthenticatedLayout = ({
  pageTitle,
  children,
  border = true,
  overFlow = true,
}: Props) => {
  //TODO: Add this back in?
  // const { loading, isAuthenticated } = useAuth();
  const router = useRouter();

  const bgColor = useColorModeValue("gray.50", "gray.900");

  const insets = useSafeAreaInsets();
  // useEffect(() => {
  //   // check if the session is loading or the router is not ready
  //   if (loading || !router.isReady) return;

  //   // if the user is not authorized, redirect to the login page
  //   // with a return url to the current page
  //   //If there's already a returnUrl, just redirect back to that
  //   if (!isAuthenticated) {
  //     //TODO: Back to push instead of replace???
  //     router.replace({
  //       pathname: "/",
  //       query: { returnUrl: router.asPath },
  //     });
  //   }
  // }, [loading, isAuthenticated, router]);

  // if (loading || !router.isReady) {
  //   return (
  //     <div style={{ height: "100vh" }}>
  //       <LoadingSpinner />
  //     </div>
  //   );
  // }

  return (
    <Container maxW={"100vw"} p={0} bg={bgColor}>
      {/*//TODO: Add a proper loading scenario here??? (Or leave it blank as below, so it doesnt show a weird loading spinner at the start) */}
      {/*//TODO: Add this back in? It stops SSR crawler / social media preview from working */}
      {
        /*loading || !router.isReady */ false ? (
          <>
            <LoadingSpinner fullHeight />
          </>
        ) : (
          //TODO: Make sure DVH is supported everywhere. It seems to be ok, but this automatically accounts for mobile devices e.g. tabs, the browser bar etc
          //TODO: More Mobile testing with pos=fixed. sometimes the scroll bar seems a little janky?
          // <Flex h={"100dvh"} w="100vw" direction="column">
          <Flex
            bg={bgColor}
            pos={"fixed"}
            // style={{
            //   height: "100vh",
            //   maxHeight: "100%",
            // }}
            h={`calc(100% - ${insets?.insets.top || 0}px)`}
            w="100vw"
            direction="column"
          >
            <Navbar />
            <Paper overFlow={overFlow} border={border}>
              {children}
            </Paper>
          </Flex>
        )
      }
    </Container>
  );
};

export default UnAuthenticatedLayout;
