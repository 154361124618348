import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import apiClient from "../../apiClient/apiClient";
import {
  CreateShoppingListCommand,
  ShoppingListVm,
} from "../../apiClient/clients";
import { firebaseLogEvent } from "../../config/firebase";
import { ProblemDetails } from "../../utils";
import reactQueryOnApiLimitError from "../../utils/reactQueryOnApiLimitError";
import queryKeys from "../queryKeys";
import useAuthentication from "../useAuthentication";

type Props = {
  onSuccess?: (list: ShoppingListVm) => void;
  onError?: (err: ProblemDetails) => void;
};

const useCreateShoppingList = ({ onSuccess, onError }: Props) => {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuthentication();

  const { isLoading, mutate: createShoppingListMutate } = useMutation(
    async (command: CreateShoppingListCommand) => {
      await getAccessTokenSilently();

      await firebaseLogEvent("create_shopping_list");
      return apiClient.createShoppingList(command);
    },
    {
      onSuccess: async (res) => {
        await queryClient.invalidateQueries({
          queryKey: [queryKeys.shoppingLists],
        });

        toast.success("Created shopping list! 📝");
        onSuccess && onSuccess(res);
      },
      onError: (err: any) => reactQueryOnApiLimitError(err, onError),
    }
  );

  return { isLoading, createShoppingListMutate };
};

export default useCreateShoppingList;
