import * as yup from "yup";

const signInUserSchema = yup.object().shape({
  email: yup
    .string()
    .label("Email")
    .email()
    .required()
    .min(3)
    .max(100)
    .typeError("Email must provided and at least 3 characters"),
  password: yup
    .string()
    .label("Password")
    .required()
    .min(8)
    .max(100)
    .typeError("Password must provided and at least 8 characters"),
});

export default signInUserSchema;
