import { Button, Flex, useDisclosure } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import "react-photo-view/dist/react-photo-view.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import { useRouter } from "next/router";
import { useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { RecipeCollectionVm } from "../../../apiClient/clients";
import { useAuthentication, useCreateRecipeCollection } from "../../../hooks";
import AutoTextarea from "../../Inputs/TextArea";
import TextInput from "../../Inputs/TextInput";

import { CreateRecipeCollectionCommand } from "../../../apiClient/clients";
import { handleApiLimitError, ProblemDetails } from "../../../utils";
import { UpgradeModal } from "../../Modals";
import recipeCollectionSchema from "./recipeCollectionSchema";

type Props = {
  formMode: FormMode;
  recipeCollectionId?: string;
  isEditMode?: boolean;
};

export type FormMode = "create" | "read" | "edit";

const RecipeCollectionForm = ({
  recipeCollectionId: recipeId,
  formMode,
}: Props) => {
  const [apiLimitError, setApiLimitError] = useState<string | undefined>();
  const {
    isOpen: isUpgradeModalOpen,
    onOpen: onOpenUpgradeModal,
    onClose: onCloseUpgradeModal,
  } = useDisclosure();

  const handleAddApiLimitError = (err: ProblemDetails) => {
    handleApiLimitError(err, setApiLimitError, onOpenUpgradeModal);
  };

  const [isLoadingSignup, setIsLoadingSignup] = useState(false);
  const { isLoading: loading, isAuthenticated } = useAuthentication();

  const router = useRouter();

  const onSuccess = () => {
    router.push("/collections");
  };

  const isReadMode = formMode === "read";

  const textInputIsRequired = isReadMode ? false : true;

  const {
    createRecipeCollectionMutate,
    isLoading: isCreateRecipeCollectionLoading,
  } = useCreateRecipeCollection({ onSuccess, onError: handleAddApiLimitError });

  const methods = useForm<RecipeCollectionVm>({
    defaultValues: {},
    resolver: yupResolver(recipeCollectionSchema),
  });

  const {
    register,
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = methods;

  if (loading) {
    return <></>;
  }

  const isLoading = isCreateRecipeCollectionLoading;
  // ||
  // isGetRecipeLoading ||
  // isCloneRecipeLoading ||
  // isUpdateRecipeLoading ||
  // isFetching;

  const onSubmit: SubmitHandler<RecipeCollectionVm> = (data) => {
    if (!data.name) return;

    const createRecipeCollectionCommand: CreateRecipeCollectionCommand = {
      name: data.name,
      description: data.description,
    };

    //TODO: This is kind of ugly but because azure functions doesn't have [FromForm] and some problems with NSwag.... This seems like the best approach.
    createRecipeCollectionMutate(createRecipeCollectionCommand);
  };

  //   if (recipeId && (isLoading || isFetching)) {
  //     return <LoadingSpinner />;
  //   }

  const ActionButtons = () => {
    return (
      <Button
        mt={4}
        colorScheme="brand"
        isLoading={isLoading}
        type="submit"
        form="RecipeCollectionForm"
      >
        Save
      </Button>
    );
  };
  //TODO: Text Input's throw controlled/uncontrolled errors whne using controller, but NumberINput's don't 👀
  return (
    <>
      <UpgradeModal
        isOpen={isUpgradeModalOpen}
        onOpen={onOpenUpgradeModal}
        onClose={onCloseUpgradeModal}
        onCancel={onCloseUpgradeModal}
        apiLimitError={apiLimitError}
      />

      <FormProvider {...methods}>
        <Flex h="100%" direction={"column"}>
          <form
            onKeyPress={(e) => {
              if (e.key === "enter") {
                e.preventDefault();
              }
            }}
            // style={{ height: "100%" }}
            id="RecipeCollectionForm"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Flex p="25px" direction="column" gap="10px" height="100%">
              {/*//TODO: Fix all of the gaps / padding etc. Use a flex on the Form and
        just set a gap there.*/}
              <TextInput
                isReadOnly={isReadMode}
                variant="flushed"
                // variant={textInputVariant}
                {...register("name")}
                isRequired={textInputIsRequired}
                label="Name"
                placeholder={"Name"}
                errorMessage={errors.name?.message}
              />
              <AutoTextarea
                {...register("description")}
                placeholder="Something to entice people you're sharing this collection with!"
                isReadOnly={isReadMode}
                variant="flushed"
                errorMessage={errors.description?.message}
                label="Description"
              />
            </Flex>
          </form>
          <Flex
            marginTop={"auto"}
            justifyContent={"end"}
            gap="15px"
            pos={"sticky"}
            bottom="0"
            bg={"white"}
            p="10px"
            boxShadow={"0px 0 2px rgba(0, 0, 0, 0.8)"}
          >
            {isAuthenticated && (
              <Button
                mt={4}
                colorScheme="brand"
                isLoading={isLoading}
                variant="outline"
                onClick={() => router.push("/collections")}
              >
                Back
              </Button>
            )}
            <ActionButtons />
          </Flex>
        </Flex>
      </FormProvider>
    </>
  );
};

export default RecipeCollectionForm;
