import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import apiClient from "../../apiClient/apiClient";
import { firebaseLogEvent } from "../../config/firebase";
import { ProblemDetails } from "../../utils";
import reactQueryOnApiLimitError from "../../utils/reactQueryOnApiLimitError";
import queryKeys from "../queryKeys";
import useAuthentication from "../useAuthentication";

type Props = {
  onSuccess?: () => void;
  onError?: (err: ProblemDetails) => void;
};

const useAddRecipeToRecipeCollection = ({ onSuccess, onError }: Props) => {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuthentication();

  const { isLoading, mutate: addRecipeToRecipeCollectionMutate } = useMutation(
    async ({
      collectionId,
      recipeId,
    }: {
      collectionId: string;
      recipeId: string;
    }) => {
      await firebaseLogEvent("add_recipe_recipe_collection", {
        recipe_id: recipeId,
        recipe_collection_id: collectionId,
      });
      await getAccessTokenSilently();
      return apiClient.addRecipeCollectionRecipe(
        collectionId,
        recipeId,
        undefined
      );
    },
    {
      onSuccess: async (res) => {
        await queryClient.invalidateQueries({
          queryKey: [queryKeys.recipeCollections],
        });

        toast.success("Added recipe to recipe collection! 📝");
        onSuccess && onSuccess();
      },
      onError: (err: any) => reactQueryOnApiLimitError(err, onError),
    }
  );

  return { isLoading, addRecipeToRecipeCollectionMutate };
};

export default useAddRecipeToRecipeCollection;
