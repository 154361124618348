import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import toast from "react-hot-toast";
import apiClient from "../../apiClient/apiClient";
import logger from "../../utils/logger";
import queryKeys from "../queryKeys";
import useAuthentication from "../useAuthentication";

const useGetSingleRecipe = (
  isAuthenticated: boolean,
  loading: boolean,
  recipeId?: string
) => {
  const { getAccessTokenSilently, isAuthenticated: isAuth0Authenticated } =
    useAuthentication();

  const {
    data,
    isError,
    isFetching,
    isInitialLoading: isLoading,
  } = useQuery(
    [queryKeys.recipes, recipeId],
    async () => {
      if (isAuth0Authenticated && isAuthenticated) {
        await getAccessTokenSilently();
        return apiClient.getSingleRecipe(recipeId || "", undefined);
      }

      return apiClient.getSinglePublicRecipe(recipeId || "", undefined);
    },
    {
      onSuccess: (data) => {},

      onError: (error: AxiosError) => {
        console.log(error);
        logger.error(error, "Error retrieving getSingleRecipe");

        toast.error(
          `Status: ${error?.response?.status}, Code: ${error?.code} - We ran into an error! Please try again - ${error}`
        );
      },
      enabled: !!recipeId && !loading,
    }
  );

  return { isLoading, isError, data, isFetching };
};

export default useGetSingleRecipe;
