import { RecipeImageVm } from "../apiClient/clients";
import imageSizeQueryParams from "./imageSizeQueryParams";

type Props = {
  //remove the 2000_2000 one as we arent really using it.
  // sizeOption: "500_500" | "800_800" | "1000_1000" | "2000_2000";
  sizeOption: "500_500" | "800_800" | "1000_1000";

  recipeImage?: RecipeImageVm;
  height?: number;
  width?: number;
  fqdn?: boolean;
};
//WE want to pass in a recipe, and a sizeoption.
//This will check to see if the size option exists.
//If it doesn't. check to see if the image has a cdnUrl.

//If not, return mainlogo.svg

/**
 * 
This function, getImageFromRecipe, accepts a destructured object sizeOption and recipe as its props. It retrieves the image URL from the recipe based on the following conditions:

It checks if the recipe has a cdnUrl. If it does, it attempts to find a matching image variant based on the sizeOption. If a matching variant is found, its cdnUrl is returned. Otherwise, if the cdnUrl exists but no matching variant is found, the cdnUrl is returned.

If the recipe doesn't have a cdnUrl, a fallback URL, /MainLogo.svg, is returned.

If any errors occur during this process, an error message is logged, and the fallback URL, /MainLogo.svg, is returned.
 */

const getImageFromRecipe = ({
  sizeOption,
  recipeImage,
  height,
  width,
  fqdn,
}: Props): string => {
  const returnUrl = fqdn
    ? "https://www.recipelog.app/MainLogo.jpg"
    : "/MainLogo.svg";
  try {
    const foundImageVariant = recipeImage?.imageVariants?.find(
      (v) => v.size == sizeOption
    );
    if (foundImageVariant) {
      return foundImageVariant.cdnUrl || returnUrl;
    }

    if (recipeImage?.cdnUrl) {
      return recipeImage?.cdnUrl + imageSizeQueryParams(height, width);
    }

    return returnUrl;
  } catch (error) {
    console.error("Failed to get image from recipe");
    return returnUrl;
  }
};

export default getImageFromRecipe;
