import { Image } from "@chakra-ui/react";

const EmptyImagePlaceholder = () => (
  <Image
    // _hover={{ cursor: "pointer" }}
    borderRadius={"lg"}
    src={"/placeholder_image.png"}
    alt="Recipe Image"
    objectFit="contain"
    height={200}
  />
);

export default EmptyImagePlaceholder;
