import { useQuery } from "@tanstack/react-query";
import toast from "react-hot-toast";
import apiClient from "../../apiClient/apiClient";
import logger from "../../utils/logger";
import queryKeys from "../queryKeys";
import useAuthentication from "../useAuthentication";

const useGetRecipeCollections = (
  searchValue?: string,
  sortBy?: string,
  isEnabled = true
) => {
  const { getAccessTokenSilently } = useAuthentication();

  const { isLoading, data, isError, isSuccess, isFetching } = useQuery(
    [queryKeys.recipeCollections, searchValue, sortBy],
    async () => {
      await getAccessTokenSilently();

      return apiClient.getCollections(searchValue, sortBy, undefined);
    },
    {
      onSuccess: (data) => {},

      onError: (error: any) => {
        logger.error(
          { error /*...authData, isAuthenticated*/ },
          "Error retrieving recipe collections"
        );
        toast.error(
          `Status: ${error.status}- We ran into an error! Please try again - ${error}`
        );
      },
      enabled: isEnabled,
    }
  );

  return { isLoading, isError, data, isSuccess, isFetching };
};

export default useGetRecipeCollections;
