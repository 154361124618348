import { Center, ResponsiveValue, Spinner } from "@chakra-ui/react";

type Props = {
  fullHeight?: boolean;
  size?: ResponsiveValue<(string & {}) | "sm" | "md" | "lg" | "xl" | "xs">;
};
const LoadingSpinner = ({ fullHeight, size }: Props) => {
  return (
    <Center h={fullHeight ? "100vh" : "100%"}>
      <Spinner color={"brand.500"} size={size ? size : "xl"} />
    </Center>
  );
};

export default LoadingSpinner;
