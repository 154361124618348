import { useMutation, useQueryClient } from "@tanstack/react-query";
import apiClient from "../../apiClient/apiClient";
import { UpdateUserCommand, UserVm } from "../../apiClient/clients";
import { firebaseLogEvent } from "../../config/firebase";
import { ProblemDetails } from "../../utils";
import reactQueryOnApiLimitError from "../../utils/reactQueryOnApiLimitError";
import queryKeys from "../queryKeys";
import useAuthentication from "../useAuthentication";

type Props = {
  onSuccess?: (list: UserVm) => void;
  onError?: (err: ProblemDetails) => void;
};

const useUpdateUser = ({ onSuccess, onError }: Props) => {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuthentication();

  const { isLoading: isUpdateUserLoading, mutate: updateUser } = useMutation(
    async (command: UpdateUserCommand) => {
      await getAccessTokenSilently();

      await firebaseLogEvent("update_user");
      return await apiClient.updateUser(command);
    },
    {
      onSuccess: async (res) => {
        await queryClient.invalidateQueries({
          queryKey: [queryKeys.users],
        });
        await queryClient.invalidateQueries({
          queryKey: [queryKeys.recipes],
        });

        onSuccess && onSuccess(res);
      },
      onError: (err: any) => reactQueryOnApiLimitError(err, onError),
    }
  );

  return { isUpdateUserLoading, updateUser };
};

export default useUpdateUser;
