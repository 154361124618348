import * as yup from "yup";

const recipeCollectionSchema = yup.object().shape({
  name: yup
    .string()
    .required()
    .min(3)
    .max(100)
    .typeError("Name must provided and at least 3 characters"),
  description: yup.string().max(250),
});

export default recipeCollectionSchema;
