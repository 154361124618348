import {
  Modal,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { ShoppingListForm } from "../Forms";
import UpgradeModal from "./UpgradeModal";

type Props = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  onToggle?: () => void;
  onCancel?: () => void;
  onSubmit: () => void;
  recipeId: string;
};

const CreateShoppingListModal = ({
  isOpen,
  onClose,
  onOpen,
  onCancel,
  onSubmit,
  recipeId,
}: Props) => {
  const cancelRef = React.useRef() as any;
  const [apiLimitError, setApiLimitError] = useState<string | undefined>();
  const {
    isOpen: isUpgradeModalOpen,
    onOpen: onOpenUpgradeModal,
    onClose: onCloseUpgradeModal,
  } = useDisclosure();

  const handleClose = () => {
    setApiLimitError(undefined);
    onCloseUpgradeModal();
  };

  return (
    <>
      <UpgradeModal
        isOpen={isUpgradeModalOpen || !!apiLimitError}
        onOpen={onOpenUpgradeModal}
        onClose={handleClose}
        onCancel={handleClose}
        apiLimitError={apiLimitError}
      />
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay>
          <ModalContent>
            <ShoppingListForm
              setIsCreateShoppingListApiLimitError={setApiLimitError}
              formMode="create"
              onBack={() => {
                onClose();
                onCancel && onCancel();
              }}
              onSave={() => {
                onClose();
                onSubmit();
              }}
              recipeId={recipeId}
            />
          </ModalContent>
        </ModalOverlay>
      </Modal>
    </>
  );
};

export default CreateShoppingListModal;
