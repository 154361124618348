import { Flex, Text, useDisclosure } from "@chakra-ui/react";
import {
  Menu,
  MenuButton,
  MenuDivider,
  MenuHeader,
  MenuItem,
  SubMenu,
} from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import { useState } from "react";
import {
  AiFillCheckCircle,
  AiOutlineLink,
  AiOutlinePlus,
} from "react-icons/ai";
import { BsThreeDotsVertical } from "react-icons/bs";
import { MdLibraryAdd } from "react-icons/md";
import { RiDeleteBin5Line } from "react-icons/ri";
import { RecipeVm } from "../../apiClient/clients";
import {
  useAddRecipeToRecipeCollection,
  useAddRecipeToShoppingList,
  useAuthentication,
  useCloneRecipe,
  useDeleteRecipe,
  useGetRecipeCollections,
  useGetShoppingLists,
} from "../../hooks";
import useGetUser from "../../hooks/users/useGetUser";
import { brand500Color } from "../../pages/_app";
import {
  handleApiLimitError,
  ProblemDetails,
  sortedRecipeCollectionItems,
  sortShoppingListsByOwned,
  writeToClipboard,
} from "../../utils";
import MenuTextItem from "../Cards/MenuTextItem";
import { LoadingSpinner } from "../LoadingSpinner";
import { UpgradeModal } from "../Modals";
import CreateShoppingListModal from "../Modals/CreateShoppingListModal";
import DeleteAlertDialog from "../Modals/DeleteAlertDialog";

type Props = {
  recipe: RecipeVm;
  isAccessedFromCollectionPage?: boolean;
  onDelete?: () => void;
};

const RecipeMenu = ({
  recipe,
  isAccessedFromCollectionPage,
  onDelete,
}: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [apiLimitError, setApiLimitError] = useState<string | undefined>();

  const { cloneRecipeMutate, isLoading: isCloneRecipeLoading } = useCloneRecipe(
    {}
  );

  const { isLoading: loading, isAuthenticated } = useAuthentication();

  const { isLoading: isGetUserLoading, data: getUserData } =
    useGetUser(isAuthenticated);

  const {
    isOpen: isCreateShoppingListModalOpen,
    onOpen: onOpenShoppingListModal,
    onClose: onCloseShoppingListModal,
  } = useDisclosure();

  const {
    isOpen: isUpgradeModalOpen,
    onOpen: onOpenUpgradeModal,
    onClose: onCloseUpgradeModal,
  } = useDisclosure();

  const handleAddApiLimitError = (err: ProblemDetails) => {
    handleApiLimitError(err, setApiLimitError, onOpenUpgradeModal);
  };

  const { addRecipeToShoppingListMutate } = useAddRecipeToShoppingList({
    onError: handleAddApiLimitError,
  });

  const { addRecipeToRecipeCollectionMutate } = useAddRecipeToRecipeCollection({
    onError: handleAddApiLimitError,
  });

  const isCreatedByCurrentUser = recipe.createdByUserId == getUserData?.id;

  const showDeleteButton = isAuthenticated && isCreatedByCurrentUser;

  const {
    data: recipeCollectionData,
    isLoading: isGetRecipeCollectionsLoading,
    isFetching: isGetRecipeCollectionsFetching,
  } = useGetRecipeCollections(
    undefined,
    undefined,
    !isAccessedFromCollectionPage
  );

  const isDataLoading =
    isGetRecipeCollectionsLoading || isGetRecipeCollectionsFetching;
  //TODO: is this used anywhere?
  const { deleteRecipeMutate, isLoading: isDeleteLoading } = useDeleteRecipe(
    {}
  );

  const handleShoppingListModalSubmit = () => console.log("submit");
  const handleCancelDelete = () => console.log("cancel");

  const handleSubmitDelete = () => {
    onDelete && onDelete();
    deleteRecipeMutate(recipe.id);
  };

  const {
    data: shoppingListData,
    isLoading: isGetShoppingListLoading,
    isFetching,
  } = useGetShoppingLists(undefined, !isAccessedFromCollectionPage);

  return (
    <>
      <CreateShoppingListModal
        recipeId={recipe.id}
        isOpen={isCreateShoppingListModalOpen}
        onOpen={onOpenShoppingListModal}
        onClose={onCloseShoppingListModal}
        onCancel={onCloseShoppingListModal}
        onSubmit={handleShoppingListModalSubmit}
      />
      <UpgradeModal
        isOpen={isUpgradeModalOpen}
        onOpen={onOpenUpgradeModal}
        onClose={onCloseUpgradeModal}
        onCancel={onCloseUpgradeModal}
        apiLimitError={apiLimitError}
      />
      <DeleteAlertDialog
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        onCancel={handleCancelDelete}
        onSubmit={handleSubmitDelete}
      />
      <Menu
        onClick={(e) => e.stopPropagation()}
        menuStyle={{ maxWidth: "250px" }}
        menuButton={
          <MenuButton
            style={{ height: "25px", width: "50px" }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <BsThreeDotsVertical style={{ marginLeft: "25px" }} />
          </MenuButton>
        }
      >
        <MenuItem
          onClick={() => {
            writeToClipboard(`recipes/${recipe.id}`);
          }}
        >
          <MenuTextItem
            icon={<AiOutlineLink size="20px" />}
            text="Copy Share Link"
          />
        </MenuItem>
        {isAuthenticated && (
          <>
            <MenuDivider />
            {isCreatedByCurrentUser ? (
              <>
                <SubMenu label="Add to recipe collection">
                  <MenuHeader>Recipe Collections</MenuHeader>

                  {isDataLoading ? (
                    <LoadingSpinner />
                  ) : (
                    <Flex
                      flexDirection={"column"}
                      minHeight="100px"
                      maxHeight="250px"
                      maxW={"250px"}
                      overflowY={"auto"}
                    >
                      {recipeCollectionData?.length ? (
                        sortedRecipeCollectionItems(
                          recipe,
                          recipeCollectionData
                        )?.map((r) => {
                          const isAlreadyAdded = !!r.recipes?.find(
                            (x) => x.recipeId === recipe.id
                          );
                          return (
                            <MenuItem
                              disabled={isAlreadyAdded}
                              key={r.id}
                              onClick={(e) => {
                                addRecipeToRecipeCollectionMutate({
                                  collectionId: r.id,
                                  recipeId: recipe.id,
                                });
                              }}
                            >
                              <Flex alignItems={"center"} gap="5px">
                                {isAlreadyAdded && (
                                  <AiFillCheckCircle color="green" />
                                )}
                                <Text noOfLines={1}>{r.name}</Text>
                              </Flex>
                            </MenuItem>
                          );
                        })
                      ) : (
                        <Text
                          padding={"15px"}
                        >{`Create a recipe collection to get started`}</Text>
                      )}
                    </Flex>
                  )}
                </SubMenu>

                <SubMenu label="Add to shopping list">
                  <MenuHeader>Shopping Lists</MenuHeader>

                  {isGetShoppingListLoading ? (
                    <LoadingSpinner />
                  ) : (
                    <Flex
                      flexDirection={"column"}
                      minHeight="100px"
                      maxHeight="250px"
                      maxW={"250px"}
                      overflowY={"auto"}
                    >
                      <MenuItem
                        onClick={(e) => {
                          e.stopPropagation = true;
                          onOpenShoppingListModal();
                        }}
                      >
                        <MenuTextItem
                          icon={<AiOutlinePlus color="#8952e0" />}
                          text="Create a shopping list"
                        />
                      </MenuItem>

                      {sortShoppingListsByOwned(shoppingListData, recipe)?.map(
                        (r) => {
                          const isAlreadyAdded = !!r.recipes?.find(
                            (x) => x.id === recipe.id
                          );
                          return (
                            <MenuItem
                              disabled={isAlreadyAdded}
                              key={r.id}
                              onClick={(e) => {
                                addRecipeToShoppingListMutate({
                                  shoppingListId: r.id,
                                  recipeId: recipe.id,
                                });
                              }}
                            >
                              <Flex alignItems={"center"} gap="5px">
                                {isAlreadyAdded && (
                                  <AiFillCheckCircle color="green" />
                                )}
                                <Text noOfLines={1}>{r.name}</Text>
                              </Flex>
                            </MenuItem>
                          );
                        }
                      )}
                    </Flex>
                  )}
                </SubMenu>
              </>
            ) : (
              <MenuItem
                disabled={isCloneRecipeLoading}
                onClick={() => cloneRecipeMutate(recipe.id)}
              >
                <MenuTextItem
                  icon={
                    <MdLibraryAdd
                      size="20px"
                      fontWeight={"bold"}
                      color={brand500Color}
                    />
                  }
                  text="Save to my recipes"
                />
              </MenuItem>
            )}

            {showDeleteButton && (
              <MenuItem
                onClick={() => {
                  onOpen();
                }}
              >
                <MenuTextItem
                  icon={<RiDeleteBin5Line color="red" />}
                  text="Delete"
                />
              </MenuItem>
            )}
            <MenuDivider />
          </>
        )}
      </Menu>
    </>
  );
};

export default RecipeMenu;
