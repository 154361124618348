//Unfortunately there's some issues with FormData and Azure functions (can't just use [FromForm] etc which causes problems with NSwag)

import axios, { AxiosRequestConfig } from "axios";
import logger from "../utils/logger";
import { defaultApiUrl } from "./defaultApiUrl";

//Temporarily defining a manual API req to handle this until a proper solution is found:

const uploadImage = async (
  imageFormData: FormData,
  recipeId: string,
  onUploadProgress: (progressEvent: any) => void
): Promise<void> => {
  //   const url = process.env.BACKEND_API_URL + "/api/images";
  // if (!process.env.NEXT_PUBLIC_IMAGE_API_URL || defaultApiUrl) {
  //   throw new Error("Must provide an image_api_url");
  // }

  const apiUrl = process.env.NEXT_PUBLIC_IMAGE_API_URL || defaultApiUrl;
  console.log("URL", defaultApiUrl);
  try {
    const options: AxiosRequestConfig = {
      method: "POST",
      data: imageFormData,
      url: apiUrl + "/api/recipes/images" + `/${recipeId}`,
      onUploadProgress,
      // headers: {},
    };
    //   console.log(url);
    await axios.request(options);
  } catch (error) {
    console.error("Ran into an error uploading image", error);
    logger.error(error, "Uploading Images");
    throw error;
  }
};

export default uploadImage;
