import { Flex, Text } from "@chakra-ui/react";
import { Cell, Label, Pie, PieChart, ResponsiveContainer } from "recharts";
import { RecipeVm } from "../../apiClient/clients";

type Props = {
  recipe: RecipeVm;
};

const NutritionInfoChart = ({ recipe: { nutritionInformation } }: Props) => {
  // Prepare data
  // Prepare data
  let data = [
    {
      name: "Protein",
      value: nutritionInformation?.protein || 0.0001,
      color: "#3182CE",
    },
    {
      name: "Fats",
      value: nutritionInformation?.fats || 0.0001,
      color: "#38A169",
    },
    {
      name: "Carbs",
      value: nutritionInformation?.carbohydrates || 0.0001,
      color: "#8952e0",
    },
  ];

  let total = 0;
  //   data.push({
  //     name: "Protein",
  //     value: nutritionInformation?.protein || 0,
  //     color: "#3182CE",
  //   });
  total += nutritionInformation?.protein || 0;

  //   data.push({
  //     name: "Fats",
  //     value: nutritionInformation?.fats || 0,
  //     color: "#38A169",
  //   });
  total += nutritionInformation?.fats || 0;

  //   data.push({
  //     name: "Carbs",
  //     value: nutritionInformation?.carbohydrates || 0,
  //     color: "#8952e0",
  //   });
  total += nutritionInformation?.carbohydrates || 0;

  // Calculate percentages
  //   const dataWithPercentage = data.map((item) => ({
  //     ...item,
  //     percentage: total ? ((item.value / total) * 100).toFixed(2) : 0,
  //   }));

  const dataWithPercentage = data.map((item) => ({
    ...item,
    percentage: total ? Math.round((item.value / total) * 100) : 0,
  }));

  return (
    <Flex textAlign="center" alignItems={"center"} flexWrap="wrap">
      <ResponsiveContainer width={125} height={150}>
        <PieChart /*height={100} width={100}*/>
          <Pie
            dataKey="value"
            data={data}
            cx={45}
            cy={60}
            innerRadius={40}
            outerRadius={50} //this controls the size of it
            fill="#8884d8"
            paddingAngle={2}
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
            <Label
              value={(nutritionInformation?.calories || 0) + " cal"}
              position="center"
              fill="#000"
              style={{ fontSize: "20px" }}
            />
          </Pie>
        </PieChart>
      </ResponsiveContainer>
      {/* <Text fontSize="xl">{nutritionInformation?.calories ? "cal" : ""}</Text> */}
      <Flex gap="15px">
        {dataWithPercentage.map((entry) => (
          <Flex key={entry.name} direction="column" alignItems="center">
            <Text color={entry.color}>{`${entry.percentage}%`}</Text>
            <Text fontSize={"md"} fontWeight="bold">{`${
              entry.value > 0.0001 ? entry.value : 0
            }g`}</Text>
            <Text>{entry.name}</Text>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};

export default NutritionInfoChart;
