import { Flex, Text } from "@chakra-ui/react";
import { ReactElement } from "react";

const MenuTextItem = ({ icon, text }: { icon: ReactElement; text: string }) => (
  <Flex gap="15px" justifyContent={"space-between"} alignItems="center">
    <Flex>{icon}</Flex>
    <Text noOfLines={1} maxW={"200px"}>
      {text}
    </Text>
  </Flex>
);

export default MenuTextItem;
