import { Button, Flex, useDisclosure } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import "react-photo-view/dist/react-photo-view.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import { useRouter } from "next/router";
import { useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import {
  CreateShoppingListCommand,
  ShoppingListVm,
} from "../../../apiClient/clients";
import {
  useAddRecipeToShoppingList,
  useAuthentication,
  useCreateShoppingList,
} from "../../../hooks";
import AutoTextarea from "../../Inputs/TextArea";
import TextInput from "../../Inputs/TextInput";

import { handleApiLimitError, ProblemDetails } from "../../../utils";
import { UpgradeModal } from "../../Modals";
import shoppingListSchema from "./shoppingListSchema";

type Props = {
  formMode: FormMode;
  shoppingListId?: string;
  isEditMode?: boolean;
  onBack?: () => void;
  onSave?: () => void;
  recipeId?: string;
  setIsCreateShoppingListApiLimitError?: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
};

export type FormMode = "create" | "read" | "edit";

const ShoppingListForm = ({
  shoppingListId,
  formMode,
  onBack,
  onSave,
  recipeId,
  setIsCreateShoppingListApiLimitError,
}: Props) => {
  const { isLoading: loading, isAuthenticated } = useAuthentication();
  const [apiLimitError, setApiLimitError] = useState<string | undefined>();
  const {
    isOpen: isUpgradeModalOpen,
    onOpen: onOpenUpgradeModal,
    onClose: onCloseUpgradeModal,
  } = useDisclosure();

  const router = useRouter();

  //So, because we're also rendering this form in a modal.
  //We cant just render another modal, within a modal.
  //So we setup an apiLimitError hook in the parent modal component and if its passed in, we set it.
  //The parent model  then displays the modal if this exists.
  //if its not passed in - then we just use the original modal.
  const handleAddApiLimitError = (err: ProblemDetails) => {
    try {
      if (setIsCreateShoppingListApiLimitError) {
        handleApiLimitError(
          err,
          setIsCreateShoppingListApiLimitError,
          () => {}
        );
      } else {
        handleApiLimitError(err, setApiLimitError, () => {
          onOpenUpgradeModal();
        });
      }
    } catch (error) {}
  };

  const { addRecipeToShoppingListMutate } = useAddRecipeToShoppingList({});

  const onSuccess = (list: ShoppingListVm) => {
    if (recipeId) {
      addRecipeToShoppingListMutate({
        shoppingListId: list.id,
        recipeId: recipeId,
      });
    }
    onSave ? onSave : router.push("/recipes");
  };

  const defaultNameValue = () => {
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString(undefined, {
      day: "numeric",
      month: "numeric",
    });
    const formattedDayName = currentDate.toLocaleString("en-us", {
      weekday: "long",
    });

    return `${formattedDayName} ${formattedDate}`;
  };

  const isReadMode = formMode === "read";

  const textInputIsRequired = isReadMode ? false : true;

  const { createShoppingListMutate, isLoading: isCreateShoppingListLoading } =
    useCreateShoppingList({ onSuccess, onError: handleAddApiLimitError });

  const methods = useForm<ShoppingListVm>({
    defaultValues: {},
    resolver: yupResolver(shoppingListSchema),
  });

  const {
    register,
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = methods;

  if (loading) {
    return <></>;
  }

  const isLoading = isCreateShoppingListLoading;

  const onSubmit: SubmitHandler<ShoppingListVm> = (data) => {
    if (!data.name) return;

    const createShoppingListCommand: CreateShoppingListCommand = {
      name: data.name,
      description: data.description || undefined,
    };

    //TODO: This is kind of ugly but because azure functions doesn't have [FromForm] and some problems with NSwag.... This seems like the best approach.
    createShoppingListMutate(createShoppingListCommand);
    onSave && onSave();
  };

  const ActionButtons = () => {
    return (
      <Button
        mt={4}
        colorScheme="brand"
        isLoading={isLoading}
        type="submit"
        form="ShoppingListForm"
      >
        Save
      </Button>
    );
  };
  //TODO: Text Input's throw controlled/uncontrolled errors whne using controller, but NumberINput's don't 👀
  return (
    <>
      <UpgradeModal
        isOpen={isUpgradeModalOpen}
        onOpen={onOpenUpgradeModal}
        onClose={onCloseUpgradeModal}
        onCancel={onCloseUpgradeModal}
        apiLimitError={apiLimitError}
      />
      <FormProvider {...methods}>
        <Flex h="100%" direction={"column"}>
          <form
            onKeyPress={(e) => {
              if (e.key === "enter") {
                e.preventDefault();
              }
            }}
            // style={{ height: "100%" }}
            id="ShoppingListForm"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Flex p="25px" direction="column" gap="10px" height="100%">
              {/*//TODO: Fix all of the gaps / padding etc. Use a flex on the Form and
        just set a gap there.*/}
              <TextInput
                isReadOnly={isReadMode}
                variant="flushed"
                // variant={textInputVariant}
                {...register("name")}
                defaultValue={defaultNameValue()}
                isRequired={textInputIsRequired}
                label="Name"
                placeholder={"Name"}
                errorMessage={errors.name?.message}
              />
              <AutoTextarea
                {...register("description")}
                placeholder="Description"
                isReadOnly={isReadMode}
                variant="flushed"
                errorMessage={errors.description?.message}
                label="Description"
              />
            </Flex>
          </form>
          <Flex
            marginTop={"auto"}
            justifyContent={"end"}
            gap="15px"
            pos={"sticky"}
            bottom="0"
            bg={"white"}
            p="10px"
            boxShadow={"0px 0 2px rgba(0, 0, 0, 0.8)"}
          >
            {isAuthenticated && (
              <Button
                mt={4}
                colorScheme="brand"
                isLoading={isLoading}
                variant="outline"
                onClick={() => {
                  onBack ? onBack() : router.push("/shopping-lists");
                }}
              >
                Back
              </Button>
            )}
            <ActionButtons />
          </Flex>
        </Flex>
      </FormProvider>
    </>
  );
};

export default ShoppingListForm;
