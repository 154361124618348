import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Textarea,
  TextareaProps,
} from "@chakra-ui/react";
import React from "react";
import ResizeTextarea from "react-textarea-autosize";

type Props = {
  errorMessage?: string;
  label?: string;
} & TextareaProps;

const AutoTextarea = React.forwardRef<HTMLTextAreaElement, Props>(
  (props, ref) => {
    const { errorMessage, ...rest } = props;
    return (
      <FormControl isRequired={props.isRequired} isInvalid={!!errorMessage}>
        {props.label && <FormLabel>{props.label}</FormLabel>}

        <Textarea
          focusBorderColor="brand.500"
          minH="unset"
          overflow="hidden"
          w="100%"
          resize="none"
          ref={ref}
          minRows={1}
          as={ResizeTextarea}
          {...rest}
        />
        <FormErrorMessage>{errorMessage}</FormErrorMessage>
      </FormControl>
    );
  }
);

AutoTextarea.displayName = "AutoTextarea";

export default AutoTextarea;
