import * as yup from "yup";

const signUpUserSchema = yup.object().shape({
  firstName: yup
    .string()
    .label("First Name")
    .required()
    .min(2)
    .max(100)
    .typeError("FirstName must provided and at least 3 characters"),
  lastName: yup
    .string()
    .label("Last Name")
    .required()
    .min(3)
    .max(100)
    .typeError("LastName must provided and at least 3 characters"),
  email: yup
    .string()
    .label("Email")
    .email()
    .required()
    .min(3)
    .max(100)
    .typeError("Email must provided and at least 3 characters"),
  password: yup
    .string()
    .label("Password")
    .required()
    .min(8)
    .max(100)
    .typeError("Password must provided and at least 8 characters"),
});

export default signUpUserSchema;
