import { Flex, Text } from "@chakra-ui/react";
type Props = {
  text: number;
};

const NumberedListItem = ({ text }: Props) => {
  return (
    <Flex
      // h="40px"
      // w="30px"
      borderRadius={"50%"}
      height="30px"
      width="35px"
      bgColor={"brand.500"}
      alignItems="center"
      // textAlign={"center"}
      justifyContent="center"
    >
      <Text color={"white"}>{text}</Text>
    </Flex>
  );
};

export default NumberedListItem;
