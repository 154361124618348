//TODO: Make this a Generic component <T>

import { Box, Flex, Text } from "@chakra-ui/react";

const CollectionItem = () => {
  return (
    <Box
      _hover={{ cursor: "pointer" }}
      w="100%"
      bg="white"
      p="15px"
      borderRadius={"lg"}
    >
      <Flex flexDirection="column">
        <Flex w="100%">
          <Text noOfLines={1} fontSize={"large"} fontWeight="bold">
            Healthy Eats
          </Text>
        </Flex>
        <Flex fontWeight={"semibold"}>7 recipes</Flex>
      </Flex>
      <Text noOfLines={2}>
        This is a description for the collection!This is a description for the
        collection!This is a description for the collection!This is a
        description for the collection!This is a description for the
        collection!This is a description for the collection!
      </Text>
    </Box>
  );
};

export default CollectionItem;
