import * as yup from "yup";

const shoppingListSchema = yup.object().shape({
  name: yup
    .string()
    .required()
    .min(3)
    .max(100)
    .typeError("Name must provided and at least 3 characters"),
  description: yup.string().nullable().max(250),
  ingredients: yup.array().of(
    yup.object().shape({
      name: yup.string().required().min(3).max(100).label("Ingredient"),
    })
  ),
});

export default shoppingListSchema;
