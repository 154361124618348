import { Button, Flex, FormLabel, Text } from "@chakra-ui/react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { AiOutlinePlus } from "react-icons/ai";
import { RiDeleteBin5Line } from "react-icons/ri";
import { CreateRecipeCommand } from "../../../apiClient/clients";
import { firebaseLogEvent } from "../../../config/firebase";
import { AutoTextArea } from "../../Inputs";
import { NumberedListItem } from "../../NumberedListItem";

type Props = {
  isReadOnly?: boolean;
};

const RecipeInstructionForm = ({ isReadOnly }: Props) => {
  const {
    control,
    register,
    formState: { errors, defaultValues },
  } = useFormContext<CreateRecipeCommand>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "instructions",
  });

  const isInstructonLimitReached = fields?.length >= 40;

  if (isInstructonLimitReached) {
    firebaseLogEvent("instruction_maximum_limit_reached");
  }

  return (
    <>
      <FormLabel id="InstructionsRecipeLabel">Instructions</FormLabel>

      {fields.map((field, fieldIndex) => (
        <Flex key={field.id} gap="10px" mt="15px" alignItems={"center"}>
          {/* <Text fontWeight={"bold"}>{fieldIndex + 1}.</Text> */}
          <NumberedListItem text={fieldIndex + 1} />

          <AutoTextArea
            variant={"flushed"}
            isReadOnly={isReadOnly}
            {...register(`instructions.${fieldIndex}.name`)}
            placeholder="Instruction"
            errorMessage={
              errors.instructions?.[fieldIndex]?.name?.message &&
              errors.instructions?.[fieldIndex]?.name?.message
            }
          />
          {!isReadOnly && (
            <Button
              leftIcon={<RiDeleteBin5Line color="red" size="24" />}
              color="red"
              variant={"link"}
              onClick={() => remove(fieldIndex)}
            />
          )}
        </Flex>
      ))}
      {!isReadOnly && (
        <Flex
          justifyContent={"center"}
          mt="15px"
          alignItems={"center"}
          flexDir="column"
          gap="10px"
        >
          <Button
            id="AddInstructionButton"
            isDisabled={isInstructonLimitReached}
            onClick={() => {
              append({ name: "", isOptional: false });
              const element = document.getElementById("AddInstructionButton");
              if (element) {
                setTimeout(
                  () => element.scrollIntoView({ behavior: "smooth" }),
                  0
                );
              }
            }}
            variant="outline"
            colorScheme="brand"
            leftIcon={<AiOutlinePlus />}
          >
            Add Instruction
          </Button>
          {isInstructonLimitReached && (
            <Text fontWeight={"semibold"} color="red">
              A maximum of 40 instructions can be added
            </Text>
          )}
        </Flex>
      )}
    </>
  );
};

export default RecipeInstructionForm;
