import { useSearchParams } from "next/navigation";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { isNative } from "../utils";

/**
 *
 * @param redirectPath
 * @summary redirectPath shoudl be e.g. /recipes/recipeId. not the FQDN
 * IT MUST START WITH A SLASH!!!!
 */

const useRedirectToIosApp = (redirectPath: string) => {
  if (!redirectPath.startsWith("/")) {
    console.error("Redirect path must start with a slash.");
  }
  const searchParams = useSearchParams();

  const isIosRedirect = searchParams.get("is_ios_redirect");
  const fbclid = searchParams.get("fbclid");

  const router = useRouter();

  /**
   * There seems to be some weird bug in the messenger / facebook's in-app browser, where the fbclid breaks the universal linking 50% of the time.
   * So, if there's a fbclid present. Replace the window. and redirect to www.
   * Then - if someone presses the button, they'l be redirected to the app if installed.
   * Else they'll be redirected to the app store.
   * Everything works as expected in chrome, instagram etc.
   */
  useEffect(() => {
    //Attempt to remove all query params if a fbclid is present in case thats messing with the IOS redirect.
    //this can probably check for any query params
    if (fbclid && !isNative) {
      window.location.replace(`https://www.recipelog.app${redirectPath}`);
      // router.reload();
      // router.push(`/recipes/${recipeId}`, undefined, {
      //   shallow: true,
      // });
    }
  }, [fbclid]);

  useEffect(() => {
    //If the user presses "Open" from the smart banner, and they dont have the app installed. Redirect them to the ios app store to download the app.
    //Once the app is installed, any time they press open it will use smart links to open the app directly.
    //IF THEY'RE NOT NATIVE!
    //The smart banner only shows if they're not native. We then remove the query param so nobody can share it and then get people to install accidentally.

    if (!isNative && isIosRedirect == "true") {
      console.log("not native");
      console.log("Redirected from IOS", isIosRedirect);

      const redirectAppStore = async () => {
        await new Promise((r) => setTimeout(r, 500));
        try {
          router.replace(redirectPath, undefined, { shallow: true });
          // setTimeout(function () {
          //   window.location.replace(alternateUrl);
          // }, 25);
          // window.location = customUrl;
          // recipelogapp;
        } catch (error) {
          console.error("failed to remove query param");
        }

        window.location.replace(
          "https://apps.apple.com/app/id6449183570?platform=iphone"
        );
      };

      redirectAppStore();
    }
  }, [isIosRedirect]);
};

export default useRedirectToIosApp;
