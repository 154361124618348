import { useMutation } from "@tanstack/react-query";
import { useRouter } from "next/router";
import { toast } from "react-hot-toast";
import { FirebaseAuthProvider } from "../../apiClient/clients";
import {
  createNewUserAPI,
  firebaseLoginWithApple,
} from "../../config/firebase";
import logger from "../../utils/logger";

type Props = {
  onSuccess?: () => void;
  onError?: () => void;
};

const useSignInWithApple = ({ onSuccess, onError }: Props) => {
  const router = useRouter();

  const { isLoading, mutate: signInWithAppleMutate } = useMutation(
    async () => await firebaseLoginWithApple(),

    {
      onSuccess: async (user) => {
        await createNewUserAPI(user, FirebaseAuthProvider.Apple);
        await router.push("/recipes");
        onSuccess && onSuccess();
      },
      onError: (err) => {
        logger.error(err, "Error signing in with Apple");
        toast.error(`We ran into an error! Please try again - ${err}`);
      },
    }
  );

  return { isLoading, signInWithAppleMutate };
};

export default useSignInWithApple;
