import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import apiClient from "../../apiClient/apiClient";
import { firebaseLogEvent } from "../../config/firebase";
import { ProblemDetails } from "../../utils";
import reactQueryOnApiLimitError from "../../utils/reactQueryOnApiLimitError";
import queryKeys from "../queryKeys";
import useAuthentication from "../useAuthentication";

type Props = {
  onSuccess?: () => void;
  onError?: (err: ProblemDetails) => void;
};

const useAddRecipeToShoppingList = ({ onSuccess, onError }: Props) => {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuthentication();

  const { isLoading, mutate: addRecipeToShoppingListMutate } = useMutation(
    async ({
      shoppingListId,
      recipeId,
    }: {
      shoppingListId: string;
      recipeId: string;
    }) => {
      await firebaseLogEvent("add_recipe_shopping_list", {
        recipe_id: recipeId,
        shopping_list_id: shoppingListId,
      });

      await getAccessTokenSilently();
      return apiClient.addRecipeToShoppingList(
        shoppingListId,
        recipeId,
        undefined,
        undefined
      );
    },
    {
      onSuccess: async (res) => {
        await queryClient.invalidateQueries({
          queryKey: [queryKeys.shoppingLists],
        });

        toast.success("Added recipe to shopping list! 📝");
        onSuccess && onSuccess();
      },
      onError: (err: any) => reactQueryOnApiLimitError(err, onError),
    }
  );

  return { isLoading, addRecipeToShoppingListMutate };
};

export default useAddRecipeToShoppingList;
