import { Checkbox, CheckboxProps } from "@chakra-ui/react";

type Props = {} & CheckboxProps;

const BorderedCheckbox = ({ ...rest }: Props) => {
  return (
    <Checkbox
      borderStyle={"solid"}
      borderColor="brand.500"
      colorScheme={"brand"}
      {...rest}
    />
  );
};

export default BorderedCheckbox;
