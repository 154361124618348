import { useRouter } from "next/router";
import { firebaseGetAccessToken, firebaseLogout } from "../config/firebase";
import { useAuthContext } from "../contexts/AuthContext";
import { setAxiosAuthToken } from "../utils";

//https://auth0.github.io/auth0-react/interfaces/Auth0ProviderOptions.html#useRefreshTokensFallback
const useAuthentication = () => {
  const { isAuthenticated, loading: isLoading, user } = useAuthContext();

  const router = useRouter();

  const getAccessTokenSilently = async () => {
    try {
      const token = await firebaseGetAccessToken();
      if (!token) {
        throw new Error("Access token is null");
      }
      setAxiosAuthToken(token);
      return token;
    } catch (e: any) {
      await firebaseLogout();
      await router.push("/signin");
    }
  };

  return {
    getAccessTokenSilently,
    isLoading,
    isAuthenticated,
  };
};

export default useAuthentication;
