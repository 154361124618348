import { FirebaseError } from "firebase/app";
import { isNative } from "../../utils";
import logger from "../../utils/logger";
import friendlyMessages from "./friendlyErrorMessages";
import iosFriendlyMessages from "./iosFriendlyMessages";

function handleError(err: FirebaseError) {
  logger.error(err, "Error logging in with email and password");
  if (isNative) {
    return (
      iosFriendlyMessages[err.code] ||
      "An error occurred. Please try again later."
    );
  }
  const userMessage =
    friendlyMessages[err.code] || "An error occurred. Please try again later.";
  return userMessage;
}

export default handleError;
