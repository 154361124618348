import { Input } from "@chakra-ui/react";
import { Controller, FieldValues, UseControllerProps } from "react-hook-form";

type Props = {
  errorMessage?: string;
  label?: string;
  // control: Control<CreateRecipeCommand, any>;
};

export type TextInputFieldProps<T extends FieldValues> = Props &
  UseControllerProps<T>;

const TextInput = <T extends FieldValues>(props: TextInputFieldProps<T>) => {
  // const { errorMessage, label } = props;
  // const {
  //   field: { ref, ...rest },
  //   fieldState,
  // } = useController<T>(props);
  // const hasError = !!errorMessage;

  // return (
  //   <div>
  //     <FormControl isInvalid={hasError}>
  //       <FormLabel>{label}</FormLabel>
  //       <Input {...rest} ref={ref} />
  //       <FormErrorMessage>{errorMessage}</FormErrorMessage>
  //     </FormControl>
  //   </div>
  // );

  const { name, control } = props;
  // const {
  //   field: { ref, ...rest },
  //   formState: { errors },
  // } = useController<T>({ name, control });

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { ref, ...rest } }) => <Input {...rest} ref={ref} />}
    />
    // <Input
    //   ref={ref}
    //   {...rest}
    //   // error={errors[name] && `${(errors[name] as DeepMap<FieldValues, FieldError>).message}`}
    // />
  );
};

export default TextInput;
